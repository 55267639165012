import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function MTV({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.MTV.title}</title>
	</Helmet>

	<video className="cover" loop autoPlay style={{
		objectFit: 'cover',
		}} muted={true}
		src="img/project-page/mtv/mtv-MariaChimishkyan-1.mp4" 
		alt="MTV - Maria Chimishkyan 1"/>
		
	<div className="inner-wrapper">
	
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.MTV}/>
	    </Container>
	
	
	    <Container>
	    	<Row>
				<Col sm={4}>
				<img src= "img/project-page/mtv/mtv-MariaChimishkyan-2.jpg" alt="MTV — Maria Chimishkyan 2"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
				<img src= "img/project-page/mtv/mtv-MariaChimishkyan-3.jpg" alt="MTV — MariaChimishkyan 3"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
				<img src= "img/project-page/mtv/mtv-MariaChimishkyan-4.jpg" alt="MTV — MariaChimishkyan 4"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.MTV}/>
	    </Container>
	
	  {/*  <Container>
	    	<Row>
				<Col sm={6}>
				<img src= "img/project-page/mtv/mtv-MariaChimishkyan-5.png" alt="MTV —  MariaChimishkyan 5"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col xs={6} sm={3}>
				<img className="top" src= "img/project-page/mtv/mtv-MariaChimishkyan-6.png" alt="MTV — MariaChimishkyan 6"/>
				<img src= "img/project-page/mtv/mtv-MariaChimishkyan-8.png" alt="MTV — MariaChimishkyan 8"/>
				</Col>
	
				<Col xs={6} sm={3}>
				<img className="top" src= "img/project-page/mtv/mtv-MariaChimishkyan-7.png" alt="MTV — MariaChimishkyan 7"/>
				<img src= "img/project-page/mtv/mtv-MariaChimishkyan-9.png" alt="MTV — MariaChimishkyan 9"/>
				</Col>
	
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.MTV}/>
	    </Container>
	    */}
	</div>

	<ProjectIndex/>
</div>

  )
}

export default connect()(MTV);
