import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function CashAppCashTruths({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.CashAppCashTruths.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={3}>
		    		<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/cash-app-cash-truths/cashapp-MariaChimishkyan-cash-truths_1.mp4"
					alt="Cash App - Cash Truths - Maria Chimishkyan 1"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={3}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/cash-app-cash-truths/cashapp-MariaChimishkyan-cash-truths_2.mp4"
					alt="Cash App - Cash Truths - Maria Chimishkyan 2"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={3}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/cash-app-cash-truths/cashapp-MariaChimishkyan-cash-truths_3.mp4"
					alt="Cash App - Cash Truths - Maria Chimishkyan 3"/>
				</Col>

				<div className="spacer"></div>
				
				<Col sm={3}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/cash-app-cash-truths/cashapp-MariaChimishkyan-cash-truths_4.mp4"
					alt="Cash App - Cash Truths - Maria Chimishkyan 4"/>
				</Col>
			</Row>
			 <ProjectDescription projectModel={ProjectModel.CashAppCashTruths}/>
		</Container>
		
		
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(CashAppCashTruths);
