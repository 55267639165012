import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Microsoft({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">
 	 <div className="inner-wrapper">

		<Helmet>
			<meta charSet="utf-8" />
			<title>Maria Chimishkyan — {ProjectModel.Microsoft.title}</title>
		</Helmet>
		
		<Container>
		    <Row>
	    		<Col sm={2}></Col>
	    		<Col sm={8}>
		    		<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/microsoft/microsoft-MariaChimishkyan-experiments.mp4"
					alt="Microsoft - Experiments in Office Flexibility - Maria Chimishkyan 1"/>
				</Col>
				
				<div className="spacer"></div>
				
				
				
				
			</Row>

		
		<ProjectDescription projectModel={ProjectModel.Microsoft}/>
		
		</Container>
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(Microsoft);
