import React from 'react';
// Import the BrowserRouter, Route and Link components
import {Helmet} from "react-helmet";
import RouterContainer from './RouterContainer';
import ScrollToTop from "./ScrollToTop";
import { ScreenClassProvider, setConfiguration} from 'react-grid-system';
import Nav from './Nav';
import Footer from './Footer';
import './App.css';


function App({ location }) {
	
	setConfiguration({
    breakpoints: [576, 768, 800, 1200, 1600],
    containerWidths: [540, 740, 960, 1400, 1700],
	});
	
  return (
	   
	<ScreenClassProvider>
	  	<ScrollToTop />
	  	<div className="App">
	  	
	  	  	<Helmet>
		        <meta charSet="utf-8" />
		        <title>Maria Chimishkyan</title>
		        <link rel="canonical" href="http://mysite.com/example" />
		    </Helmet>
			<RouterContainer/>
			<Nav/>
			<Footer/>
	
	      </div>
	
	
	</ScreenClassProvider>
  );
}

export default App;
