import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

function ProjectIndexCard(props) {
  return (
    <div className="thumbnail">
     	<Link to={props.projectModel.pageUrl}>
        <div className="project-image">
          <img src={props.projectModel.thumbnailUrl} alt={props.projectModel.altDescription}/>
        </div>
        <div className="project-index-client">{props.projectModel.client}</div>
        <div className="project-index-title">{props.projectModel.title}</div>
        </Link>
    </div>
  )
}

export default ProjectIndexCard;
