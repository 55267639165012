import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function NewYorker_MDMA({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.NewYorker_MDMA.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={3}>
				</Col>
				<Col sm={6}>
				<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/the-new-yorker-mdma/the-new-yorker_mdma_MariaChimishkyan_1.mp4"
					alt="New Yorker - Maria Chimishkyan 1"/>
				</Col>
				<Col sm={3}>
				</Col>
				
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.NewYorker_MDMA}/>
			
		</Container>
	
	   	</div>
	
	<ProjectIndex/>
	
</div>

  )
}

export default connect()(NewYorker_MDMA);
