import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BloombergGreen({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.BloombergGreen.title}</title>
	</Helmet>
	
	<div className="inner-wrapper">		
	
	    <Container>
	    	<Row>
				<Col sm={4}>
				<img src= "img/project-page/bloomberg-green/bloomberg-green-MariaChimishkyan-1.jpg" alt="Bloomberg Green — Maria Chimishkyan 1"/>
				</Col>
				<Col sm={4}>
				<img src= "img/project-page/bloomberg-green/bloomberg-green-MariaChimishkyan-2.jpg" alt="Bloomberg Green — Maria Chimishkyan 2"/>
				</Col>
				<Col sm={4}>
				<img src= "img/project-page/bloomberg-green/bloomberg-green-MariaChimishkyan-3.jpg" alt="Bloomberg Green — Maria Chimishkyan 3"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.BloombergGreen}/>
	    </Container>
	    
	    <div className="project-section">
	    <Container>
	    	<Row>
				<Col sm={4}>
				<img src= "img/project-page/bloomberg-green/bloomberg-green-MariaChimishkyan-4.jpg" alt="Bloomberg Green — Maria Chimishkyan 4"/>
				</Col>
				<Col sm={4}>
				<img src= "img/project-page/bloomberg-green/bloomberg-green-MariaChimishkyan-5.jpg" alt="Bloomberg Green — Maria Chimishkyan 5"/>
				</Col>
				<Col sm={4}>
				<img src= "img/project-page/bloomberg-green/bloomberg-green-MariaChimishkyan-6.jpg" alt="Bloomberg Green — Maria Chimishkyan 6"/>
				</Col>
			</Row>
		</Container>
	    </div>
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(BloombergGreen);
