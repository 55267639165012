import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function HighSnobiety({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">
 	 <div className="inner-wrapper">

		<Helmet>
			<meta charSet="utf-8" />
			<title>Maria Chimishkyan — {ProjectModel.HighSnobiety.title}</title>
		</Helmet>
		
		<div className="project-section">
		<Container>
		    <Row>
				<Col sm={12}>
				<img src= "img/project-page/high-snobiety/hs-MariaChimishkyan-plasticsurgery_1.jpg" alt="High Snobiety - Plastic Surgery - Maria Chimishkyan 1"/>
				</Col>
			</Row>

		<ProjectDescription projectModel={ProjectModel.HighSnobiety}/>
		
		</Container>
		</div>
		<div className="project-section">
		<Container>

			<Row>
				<Col sm={12}>
				<img src= "img/project-page/high-snobiety/hs-MariaChimishkyan-plasticsurgery_2.jpg" alt="High Snobiety - Plastic Surgery - Maria Chimishkyan 2"/>
				</Col>
			</Row>
		
		</Container>
		</div>
		<div className="project-section">
		<Container>

			<Row>
				<Col sm={12}>
				<img src= "img/project-page/high-snobiety/hs-MariaChimishkyan-plasticsurgery_3.jpg" alt="High Snobiety - Plastic Surgery - Maria Chimishkyan 3"/>
				</Col>
			</Row>
		
		</Container>
		</div>
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(HighSnobiety);
