import { ACTION_TYPE_SET_COVER_PRESENT } from './actions';

const initialState = {
  coverPresent: 'no'
};

function rootReducer(state = initialState, action) {
  if(action.type === ACTION_TYPE_SET_COVER_PRESENT){
    return Object.assign({}, state, {
      coverPresent: action.payload
    });
  }
  return state;
};

export default rootReducer;
