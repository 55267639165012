import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function AppleArtText({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.AppleArtText.title}</title>
		</Helmet>
	
	   <Container>
	    	<Row>
	    		<Col sm={2}></Col>
	    		<Col sm={8}>
		    		<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/apple-art-text/apple-arttext-MariaChimishkyan.mp4"
					alt="Apple - Art Text - Maria Chimishkyan 1"/>
				</Col>
				
				<div className="spacer"></div>
				
				
				
				
			</Row>
			 <ProjectDescription projectModel={ProjectModel.AppleArtText}/>
		</Container>

	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(AppleArtText);
