import React from "react";
import { render } from "react-dom";
import './index.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from './redux/store';
import App from './App';

const AppWithRouters = () => (
  <Provider store={store}>
    <Router>
       <App />
    </Router>
  </Provider>
);

render(<AppWithRouters />, document.getElementById("root"));
