import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function ProjectHomeCard(props) {
  return (
    <div >
    	<div className="rollover" style={{backgroundColor: [props.projectModel.rolloverColor]}}>
    		<Link to={props.projectModel.pageUrl} className="item" >
				<img className="homepage-image" src={props.projectModel.imageUrl}  alt={props.projectModel.altDescription}/>
			</Link>
		</div>
		<div className="caption">{props.projectModel.client}<br/></div>
		<div className="title">{props.projectModel.title}<br/></div>
    </div>
  	);
}

export default ProjectHomeCard;
