import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BloombergBusinessweekCurrencies({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">
 	 <div className="inner-wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.BloombergBusinessweekCurrencies.title}</title>
	</Helmet>
		

		<Container>
		<Row>
		    	<Col sm={4}>
		    	<img src= "img/project-page/bloomberg-businessweek-currencies/bloomberg-businessweek-currencies-MariaChimishkyan-1.jpg" alt="Bloomberg Businessweek Currencies Cover — Maria Chimishkyan 1"/>
				</Col>
			
				<Col sm={4}>
				<img className="mobile-hide" src= "img/project-page/bloomberg-businessweek-currencies/bloomberg-businessweek-currencies-MariaChimishkyan-1.jpg" alt="Bloomberg Businessweek Currencies Cover — Maria Chimishkyan 1"/>
				</Col>
				<Col sm={4}>
				<img className="mobile-hide" src= "img/project-page/bloomberg-businessweek-currencies/bloomberg-businessweek-currencies-MariaChimishkyan-1.jpg" alt="Bloomberg Businessweek Currencies Cover — Maria Chimishkyan 1"/>
				</Col>
			
			</Row>
	    	<ProjectDescription projectModel={ProjectModel.BloombergBusinessweekCurrencies}/>
	    </Container>
	
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(BloombergBusinessweekCurrencies);
