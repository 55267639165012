import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BushwigBerlin2018({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.BushwigBerlin2018.title}</title>
	</Helmet>
	
	<div className="inner-wrapper">
	    <Container>
	    	<Row>
	
				<Col sm={12}>
				<img src= "img/project-page/bushwig-berlin-2018/bushwig-berlin-2018-MariaChimishkyan-1.jpg" alt="Medium — Maria Chimishkyan 1"/>
				</Col>
	
			</Row>
	
	    <ProjectDescription projectModel={ProjectModel.BushwigBerlin2018}/>
	
		</Container>
	
		<div className="project-section">
		    <Container>
		    	<Row>
					<Col sm={3}>
					</Col>
					<Col sm={6}>
					<img src= "img/project-page/bushwig-berlin-2018/bushwig-berlin-2018-MariaChimishkyan-2.jpg" alt="Medium — Maria Chimishkyan 2"/>
					<div className="project-caption">Bushwig Berlin Poster</div>
					</Col>
					<Col sm={3}>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<ProjectIndex/>
</div>

  )
}

export default connect()(BushwigBerlin2018);
