import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function TheBaffler({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.TheBaffler.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={4}>
				</Col>
				<Col sm={4}>
				<img src= "img/project-page/the-baffler/the-baffler_MariaChimishkyan_1.jpg" alt="The Baffler - Maria Chimishkyan 1"/>
				</Col>
				<Col sm={4}>
				</Col>
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.TheBaffler}/>
	
		</Container>
	</div>
	
	<ProjectIndex/>
</div>

  )
}

export default connect()(TheBaffler);
