import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function SportsFandom({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.SportsFandom.title}</title>
	</Helmet>
	
	
	
	<div className="inner-wrapper">		
	    
	    <Container>
	    	<Row>
	    		<Col sm={12}>
				<img src= "img/project-page/the-verge-offline-activities/theverge-offlineactivities-MariaChimishkyan-1.jpg" alt="The Verge — Offline Activities — Maria Chimishkyan 1"/>
				</Col>
			</Row>
		</Container>
		
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.SportsFandom}/>
	    </Container>
		
		<Container>
	    	<Row>
	    		<Col sm={12}>
				<img src= "img/project-page/the-verge-offline-activities/theverge-offlineactivities-MariaChimishkyan-2.jpg" alt="The Verge — Offline Activities — Maria Chimishkyan 2"/>
				</Col>
			</Row>
		</Container>
		
	
	
	    
	    
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(SportsFandom);
