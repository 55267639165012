import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function ElectricObjects({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.ElectricObjects.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={4}>
		    		<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/electric-objects/electric-objects-MariaChimishkyan-1.mp4"
					alt="Electric Objects - Maria Chimishkyan 1"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/electric-objects/electric-objects-MariaChimishkyan-2.mp4"
					alt="Electric Objects - Maria Chimishkyan 2"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/electric-objects/electric-objects-MariaChimishkyan-3.mp4"
					alt="Electric Objects - Maria Chimishkyan 3"/>
				</Col>
			</Row>
			 <ProjectDescription projectModel={ProjectModel.ElectricObjects}/>
		</Container>
		
		<div className="project-section">
			<Container>
		
		    	<Row>
		    		<Col sm={4}>
			    		<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/electric-objects/electric-objects-MariaChimishkyan-4.mp4"
						alt="Electric Objects - Maria Chimishkyan 4"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/electric-objects/electric-objects-MariaChimishkyan-5.mp4"
						alt="Electric Objects - Maria Chimishkyan 5"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<video className="reg" loop autoPlay style={{
						objectFit: 'contain',
						}}
						src="img/project-page/electric-objects/electric-objects-MariaChimishkyan-6.mp4"
						alt="Electric Objects - Maria Chimishkyan 6"/>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(ElectricObjects);
