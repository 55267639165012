import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Bandcamp({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.Bandcamp.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={4}>
	    		<img src= "img/project-page/bandcamp/bandcamp-MariaChimishkyan-1.gif" alt="Bandcamp — Maria Chimishkyan 1"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
				<img src= "img/project-page/bandcamp/bandcamp-MariaChimishkyan-2.gif" alt="Bandcamp — Maria Chimishkyan 2"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
				<img src= "img/project-page/bandcamp/bandcamp-MariaChimishkyan-3.gif" alt="Bandcamp — Maria Chimishkyan 3"/>
				</Col>
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.Bandcamp}/>
	
		</Container>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(Bandcamp);
