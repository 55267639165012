import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function LATimesClimateChange({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">
 	 <div className="inner-wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.LATimesClimateChange.title}</title>
	</Helmet>
	
	    <Container>
	    	<Row>
	    		<Col sm={3}>
	    		</Col>
				<Col sm={6}>
				<img src= "img/project-page/latimes-climatechange/latimes-MariaChimishkyan-climatechange.jpg" alt="LA Times - Climate Change — Maria Chimishkyan 2"/>
				</Col>
				<Col sm={3}>
	    		</Col>
			</Row>
		</Container>
		
		<Container>
			<ProjectDescription projectModel={ProjectModel.LATimesClimateChange}/>
	    </Container>
	    
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(LATimesClimateChange);
