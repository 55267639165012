import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Elsewhere({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.Elsewhere.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
				<Col sm={12}>
				<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/elsewhere/elsewhere-MariaChimishkyan-1.mp4"
					alt="Elsewhere - Maria Chimishkyan 1"/>
				</Col>
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.Elsewhere}/>
	
		</Container>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(Elsewhere);
