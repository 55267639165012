import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function NewYorker_BitterPill({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.NewYorker_BitterPill.title}</title>
		</Helmet>
		<div className="project-section">
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/the-new-yorker-bitter-pill/the-new-yorker_bitterpill_MariaChimishkyan.jpg" alt="New Yorker — Maria Chimishkyan 1"/>
				</Col>
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.NewYorker_BitterPill}/>
		</Container>
		</div>

		<div className="project-section">
		<Container>
			<Row>
	    		<Col sm={3}>
				</Col>
				<Col sm={6}>
				<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/the-new-yorker-bitter-pill/the-new-yorker_bitterpill_MariaChimishkyan-2.mp4"
					alt="New Yorker - Maria Chimishkyan 1"/>
				</Col>
				<Col sm={3}>
				</Col>
				
				<div className="spacer"></div>

			</Row>
	
		</Container>
		</div>
	   	</div>
	
	<ProjectIndex/>
	
</div>

  )
}

export default connect()(NewYorker_BitterPill);
