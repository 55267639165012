import React from 'react';
import { Row, Col } from 'react-grid-system';
import '../App.css';

function ProjectDescriptionWhite(props) {
  return (
	<div className="project-description">
	<Row>			
		<Col sm={12}>
			{props.projectModel.headline.length > 0 && (
			<div className="project-headline-black">{props.projectModel.headline}</div>
			)}
		</Col>					
	</Row>	
				
	<Row>
					
		<Col sm={3}>
			<div className="subhead-black">{props.projectModel.projectName}</div> 
			{props.projectModel.articleLink.length > 0 && (
		    <div className="subhead-black"><a href={props.projectModel.articleLink}>VIEW ARTICLE</a></div>
		    )}
		</Col>
						 
		<Col sm={3}>
			<div className="subhead-black">CLIENT — {props.projectModel.client}</div>
		</Col>
						 
		<Col sm={3}>
			<div className="subhead-black">ROLE — {props.projectModel.role}</div>
		</Col>
								
		<Col sm={3}>
			{props.projectModel.contributorRole.length > 0 && (
				<div className="subhead-black">
					{props.projectModel.contributorRole} — <a href={props.projectModel.contributorLink}>{props.projectModel.contributorName}</a><
				/div>
				
			)}
			{props.projectModel.contributorRole1.length > 0 && (
				<div className="subhead-black">
					{props.projectModel.contributorRole1} — <a href={props.projectModel.contributorLink1}>{props.projectModel.contributorName1}</a>
				</div>
			)}
		</Col>
	</Row>	
	</div>					

  )
}
 
export default ProjectDescriptionWhite;