import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Bulletin({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.Bulletin.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-1.jpg" alt="Bulletin Identity — Maria Chimishkyan 1"/>
	
	<div className="inner-wrapper">
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.Bulletin}/>
		</Container>
	
	
		<div className="project-section" style={{paddingTop: "0"}}>
			<Container>
				<Row justify="center">
					<Col sm={10}>
						<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-2-2.jpg" alt="Bulletin Identity — Maria Chimishkyan 2"/>
						<div className="project-caption">Promotional Cards</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="full-width">
			<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-2-1.jpg" alt="Bulletin Identity — Maria Chimishkyan 7"/>
		</div>
	
	
		<div className="project-section-split" style={{backgroundColor: "#fff"}}>
			<div className="split-full">
				<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-5.jpg" alt="Bulletin Identity — Maria Chimishkyan 6"/>
			</div>
			<div className="split-full">
				<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-6.jpg" alt="Bulletin Identity — Maria Chimishkyan 6"/>
			</div>
		</div>
		
		<div className="full-width">
			<video loop autoPlay style={{
			objectFit: 'cover',
			width: '100%',
			height: '100%',
			}}
			src="img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-12.mp4" />
		</div>
		
	
		<div className="project-section-split" style={{backgroundColor: "#fff"}}>
			<div className="split-full">
				<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-5_1.gif" alt="Bulletin Identity — Maria Chimishkyan 6"/>
			</div>
			<div className="split-full">
				<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-6_1.jpg" alt="Bulletin Identity — Maria Chimishkyan 6"/>
			</div>
		</div>
		

		
		<div className="full-width">
			<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-7.jpg" alt="Bulletin Identity — Maria Chimishkyan 7"/>
		</div>
	
		
	
		
		<div className="project-section-bg" style={{backgroundColor: "#fff"}}>
			<div className="inner-wrapper">
				<Container>
					<Row justify="center">
						<Col sm={6} style={{padding: "0"}}>
							<div className="diptych-left">
								<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-8.jpg" alt="Bulletin Identity — Maria Chimishkyan 8"/>
							</div>
						</Col>
						
						<div className="spacer"></div>
						
						<Col sm={6} style={{padding: "0"}}>
							<div className="diptych-right">
								<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-9.jpg" alt="Bulletin Identity — Maria Chimishkyan 9"/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	
		<div className="project-section-bg" style={{backgroundColor: "#e2e2e2"}}>
			<div className="inner-wrapper">
				<Container>
					<Row>
						<Col sm={6}>
							<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-10.gif" alt="Bulletin Identity — Maria Chimishkyan 10"/>
							<div className="project-caption-black">Events</div>
						</Col>
						
						<div className="spacer"></div>
						
						<Col sm={6}>
							<img src= "img/project-page/bulletin-brand-identity/Bulletin-Identity-Maria-Chimishkyan-11.jpg" alt="Bulletin Identity — Maria Chimishkyan 11"/>
							<div className="project-caption-black">Q&A</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	
		
	
	<ProjectIndex/>
</div>
  )
}

export default connect()(Bulletin);
