import React, { useEffect } from "react";
import InfoTextBlock from './components/InfoTextBlock';
import { Container, Row, Col } from 'react-grid-system';
import { connect } from "react-redux";
import setCoverPresent from './redux/actions';
import './App.css';

function InfoPage({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
	
  return (
	  
<div className="info-page">
	<div className="inner-wrapper">
	    <Container>
	    	
	
			<Row>
	    		<Col sm={8}>
	    		<div className="project-headline">
	    		Maria Chimishkyan is an art director, motion designer, and illustrator based in New York. Currently contract Art Director at Apple Music.
	    		<br/>
	    		<br/>
	    		Previously with NPE at Meta, Google, Bulletin, RoAndCo Studio, MTV, and Urban Outfitters. 
	    		<br/>
	    		<br/>
	    		Available for freelance and collaborations.</div>
	    		</Col>
				<Col sm={2}>
				<div className="subhead-white" style={{paddingBottom:'0px'}}>Select Clients</div>
	    		
	    		<div className="info-textblock">
		    		<div className="body-text">
			    		Apple<br/>
						Adidas<br/>
			    		The Atlantic<br/>
						Bandcamp<br/>
						Bloomberg Businessweek<br/>
						Cash App<br/>
						Elsewhere<br/>
						Google<br/>
						Medium<br/>
						Microsoft<br/>
						MTV<br/>
						The New York Times<br/>
						The New Yorker<br/>
						Nike<br/>
						Spotify<br/>
						The Verge<br/>
						Vice Magazine<br/>
						The Wall Street Journal<br/>
						Wired<br/>
					
		    		</div>
		    		<div className="spacer"></div>
		    	</div>
	
				</Col>
	
				<Col sm={2}>
	
				<div className="subhead-white" style={{paddingBottom:'0px'}}>Contact</div>
	    		
	    		<div className="info-textblock">
		    		<div className="body-text" style={{paddingBottom:'20px'}}>
		    		hi@mariachimi.com
		    		</div>
	    		</div>
	
	    	
	
	    		<div className="subhead-white" style={{paddingBottom:'0px'}}>Creative Services</div>
	    		
	    		<div className="info-textblock">
		    		<div className="body-text">
		    		3D Graphics<br/>
		    		Art Direction<br/>
		    		Brand Design<br/>
		    		Illustration<br/>
		    		Motion Design<br/>
					Product Design<br/>	
					Visual Design<br/>
		    		</div>
		    		
	    		</div>

				</Col>
			</Row>
			
		</Container>
	</div>
</div>

  )
}

export default connect()(InfoPage);
