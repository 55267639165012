import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BloombergBusinessweek({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
  <div className="wrapper">

	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.BloombergBusinessweek.title}</title>
	</Helmet>
	
	<img className="cover" src= "img/project-page/bloomberg-businessweek/bloomberg-businessweek-MariaChimishkyan-7.jpg" alt="Bloomberg Businessweek — Maria Chimishkyan 7"/>
	
	<div className="inner-wrapper">
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.BloombergBusinessweek5}/>
	    </Container>
	    
	     <Container>
	    	<Row>
	    		<Col sm={12}>
				<img src= "img/project-page/bloomberg-businessweek/bloomberg-businessweek-MariaChimishkyan-9.jpg" alt="Bloomberg Businessweek — Maria Chimishkyan 1"/>
				</Col>
			</Row>
		</Container>
		
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.BloombergBusinessweek7}/>
	    </Container>
	
	    <Container>
	    	<Row>
				<Col sm={7}>
				<img src= "img/project-page/bloomberg-businessweek/bloomberg-businessweek-MariaChimishkyan-3.gif" alt="Bloomberg Businessweek — Maria Chimishkyan 3"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={5}>
				<img src= "img/project-page/bloomberg-businessweek/bloomberg-businessweek-MariaChimishkyan-4.jpg" alt="Bloomberg Businessweek — Maria Chimishkyan 4"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.BloombergBusinessweek2}/>
	    </Container>
	
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/bloomberg-businessweek/bloomberg-businessweek-MariaChimishkyan-5.jpg" alt="Bloomberg Businessweek — Maria Chimishkyan 5"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.BloombergBusinessweek3}/>
	    </Container>
	    
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/bloomberg-businessweek/bloomberg-businessweek-MariaChimishkyan-6.jpg" alt="Bloomberg Businessweek — Maria Chimishkyan 6"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.BloombergBusinessweek4}/>
	    </Container>
	    
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(BloombergBusinessweek);
