import React from 'react';
import ReactGA from 'react-ga';
import { Switch, Route, withRouter, useHistory } from "react-router-dom";
import './App.css';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Homepage from './Homepage';

import AdidasStateSneakers from './projects/AdidasStateSneakers';
import Afterfest from './projects/Afterfest';
import Apple from './projects/Apple';
import AppleArtText from './projects/AppleArtText';
import Atlantic from './projects/Atlantic';
import Bandcamp from './projects/Bandcamp';
import BandcampExperimental from './projects/BandcampExperimental';
import BloombergBusinessweek from './projects/BloombergBusinessweek';
import BloombergBusinessweekCover from './projects/BloombergBusinessweekCover';
import BloombergBusinessweekCurrencies from './projects/BloombergBusinessweekCurrencies';
import BloombergGreen from './projects/BloombergGreen';
import Bulletin from './projects/BulletinBrandIdentity';
import BulletinLWY from './projects/BulletinLWY';
import BulletinRetailerNetwork from './projects/BulletinRetailerNetwork';
import BulletinSwag from './projects/BulletinSwag';
import Bushwig2018 from './projects/Bushwig2018';
import Bushwig2019 from './projects/Bushwig2019';
import BushwigBerlin2018 from './projects/BushwigBerlin2018';
import CashApp from './projects/CashApp';
import CashAppCashTruths from './projects/CashAppCashTruths';
import ElectricObjects from './projects/ElectricObjects';
import Elsewhere from './projects/Elsewhere';
import GIPHYArts from './projects/GIPHYArts';
import HighSnobiety from './projects/HighSnobiety';
import InfoPage from './InfoPage';
import LATimesClimateChange from './projects/LATimesClimateChange';
import LuckyPeach from './projects/LuckyPeach';
import LuxMagazine from './projects/LuxMagazine';
import Medium from './projects/Medium';
import Microsoft from './projects/Microsoft';
import MTV from './projects/MTV';
import NewYorker from './projects/NewYorker';
import NewYorker_BitterPill from './projects/NewYorker_BitterPill';
import NewYorker_MDMA from './projects/NewYorker_MDMA';
import Nike from './projects/Nike';
import NYTimes from './projects/NYTimes';
import NYTimesQuestionnaire from './projects/NYTimesQuestionnaire';
import NYTimesRightToBeauty from './projects/NYTimesRightToBeauty';
import PitchStudios from './projects/PitchStudios';
import RBMA from './projects/RBMA';
import SpaceNinety8 from './projects/SpaceNinety8';
import Splice from './projects/Splice';
import SportsFandom from './projects/SportsFandom';
import Spotify from './projects/Spotify';
import TheBaffler from './projects/TheBaffler';
import TheVerge from './projects/TheVerge';
import TheVergeOfflineActivities from './projects/TheVergeOfflineActivities';
import UsluAirlines from './projects/UsluAirlines';
import Wired from './projects/Wired';
import WSJ from './projects/WSJ';
import WSJLowBirths from './projects/WSJLowBirths';
import YoungGuns from './projects/YoungGuns';




function RouterContainer({ location }) {
	
  let history = useHistory();	
  ReactGA.initialize('UA-170809299-1');
  history.listen(location => ReactGA.pageview(location.pathname));
    	
    	
  return (
	<TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{ enter: 300, exit: 300 }}
      classNames="fade">
				<Switch location={location}>
				
					<Route exact path="/" component={Homepage} />
				
					<Route path="/uo-afterfest" component={Afterfest} />
					<Route path="/adidas-statesneakers" component={AdidasStateSneakers} />
					<Route path="/apple" component={Apple} />
					<Route path="/apple-art-text" component={AppleArtText} />
					<Route path="/atlantic" component={Atlantic} />
					<Route path="/bandcamp" component={Bandcamp} />
					<Route path="/bandcamp_experimental" component={BandcampExperimental} />
					<Route path="/bloomberg-businessweek" component={BloombergBusinessweek} />
					<Route path="/bloomberg-businessweek-cover" component={BloombergBusinessweekCover} />
					<Route path="/bloomberg-businessweek-currencies" component={BloombergBusinessweekCurrencies} />
					<Route path="/bloomberg-green" component={BloombergGreen} />
					<Route path="/bulletin-brandidentity" component={Bulletin} />
					<Route path="/bulletin-retailer-network" component={BulletinRetailerNetwork} />
					<Route path="/bulletin-swag" component={BulletinSwag} />
					<Route path="/bulletin-lotswithoutyou" component={BulletinLWY} />
					<Route path="/bushwig-2019" component={Bushwig2019} />
					<Route path="/bushwig-2018" component={Bushwig2018} />
					<Route path="/bushwig-berlin-2018" component={BushwigBerlin2018} />
					<Route path="/cash-app-ISpy" component={CashApp} />
					<Route path="/cash-app-cash-truths" component={CashAppCashTruths} />
					<Route path="/electric-objects" component={ElectricObjects} />
					<Route path="/elsewhere" component={Elsewhere} />					
					<Route path="/giphy-arts" component={GIPHYArts} />
					<Route path="/high-snobiety" component={HighSnobiety} />
					<Route path="/info" component={InfoPage} />
					<Route path="/lucky-peach" component={LuckyPeach} />
					<Route path="/medium" component={Medium} />
					<Route path="/microsoft" component={Microsoft} />
					<Route path="/mtv" component={MTV} />
					<Route path="/latimes-climatechange" component={LATimesClimateChange} />
					<Route path="/lux-magazine" component={LuxMagazine} />
					<Route path="/nike" component={Nike} />
					<Route path="/nytimes" component={NYTimes} />
					<Route path="/nytimes-questionnaire" component={NYTimesQuestionnaire} />
					<Route path="/nytimes-right-to-beauty" component={NYTimesRightToBeauty} />
					<Route path="/newyorker" component={NewYorker} />
					<Route path="/newyorker-bitter-pill" component={NewYorker_BitterPill} />
					<Route path="/newyorker-mdma" component={NewYorker_MDMA} />
					<Route path="/pitch-studios" component={PitchStudios} />
					<Route path="/rbma" component={RBMA} />				
					<Route path="/uo-space-ninety-8" component={SpaceNinety8} />
					<Route path="/splice" component={Splice} />	
					<Route path="/fans-have-more-friends" component={SportsFandom} />		
					<Route path="/spotify" component={Spotify} />						
					<Route path="/the-baffler" component={TheBaffler} />
					<Route path="/the-verge" component={TheVerge} />	
					<Route path="/the-verge-offline-activities" component={TheVergeOfflineActivities} />						
					<Route path="/uo-uslu-airlines" component={UsluAirlines} />		
					<Route path="/wired" component={Wired} />	
					<Route path="/wsj" component={WSJ} />		
					<Route path="/wsj-lowbirths" component={WSJLowBirths} />													
					<Route path="/youngguns" component={YoungGuns} />
					
				</Switch>
     </CSSTransition>
	</TransitionGroup>
  );
}


export default withRouter(RouterContainer);
