import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function RBMA({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (

<div className="wrapper">
	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.RBMA.title}</title>
	</Helmet>

	<video className="cover" loop autoPlay style={{
	objectFit: 'cover',
	}}
	src="img/project-page/rbma/RBMA_Rhythm_MariaChimishkyan.mp4" 
	alt="RBMA - Rhythm — Maria Chimishkyan"/>
	
	<div className="inner-wrapper">
		<Container>
			<ProjectDescription projectModel={ProjectModel.RBMA}/>
		</Container>
	
	
		<div className="project-section" >
			<Container>
				<Row className="top">
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-1999-MariaChimishkyan.gif" alt="RBMA - 1999 — Maria Chimishkyan"/>
						<div className="project-caption">1999</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-MusicInFilm-MariaChimishkyan.gif" alt="RBMA - Music In Film — Maria Chimishkyan"/>
						<div className="project-caption">Music In Film</div>
					</Col>
					
					<div className="spacer"></div>
					
						<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-Fashion-MariaChimishkyan.gif" alt="RBMA - Fashion — Maria Chimishkyan"/>
						<div className="project-caption">Fashion</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-DIY-MariaChimishkyan.gif" alt="RBMA - DIY — Maria Chimishkyan"/>
						<div className="project-caption">DIY</div>
					</Col>
				</Row>
				<Row>
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-Detroit-MariaChimishkyan.gif" alt="RBMA - Detroit — Maria Chimishkyan"/>
						<div className="project-caption">Detroit</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-AvantGarde-MariaChimishkyan.gif" alt="RBMA - Avant Garde — Maria Chimishkyan"/>
						<div className="project-caption">Avante Garde</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-Rhythm-MariaChimishkyan.gif" alt="RBMA - Rhythm — Maria Chimishkyan"/>
						<div className="project-caption">Rhythm</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={3}>
						<img src= "img/project-page/rbma/RBMA-Afrofuturism-MariaChimishkyan.gif" alt="RBMA - Afrofuturism — Maria Chimishkyan"/>
						<div className="project-caption">Afrofuturism</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>
  )
}

export default connect()(RBMA);
