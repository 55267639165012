import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Medium({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">
	
	<Helmet>
		<meta charSet="utf-8" />
		<title>Maria Chimishkyan — {ProjectModel.Medium.title}</title>
	</Helmet>
	
	<img className="cover" src= "img/project-page/medium/medium-MariaChimishkyan-1.gif" alt="Medium — Maria Chimishkyan 1"/>
	
	<div className="inner-wrapper">
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.Medium}/>
	    </Container>
	
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/medium/medium-MariaChimishkyan-2.gif" alt="Medium — Maria Chimishkyan 2"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.Medium1}/>
	    </Container>
	
	    <Container>
	    	<Row>
				<Col className="top" sm={6}>
				<img src= "img/project-page/medium/medium-MariaChimishkyan-3.gif" alt="Medium — Maria Chimishkyan 3"/>
				</Col>
				<Col sm={6}>
				<img src= "img/project-page/medium/medium-MariaChimishkyan-4.gif" alt="Medium — Maria Chimishkyan 4"/>
				</Col>
			</Row>
			
			<div className="spacer"></div>
			
			<Row>
				<Col sm={6}>
				<img src= "img/project-page/medium/medium-MariaChimishkyan-5.gif" alt="Medium — Maria Chimishkyan 5"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.Medium2}/>
	    </Container>
	
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/medium/medium-MariaChimishkyan-6.gif" alt="Medium — Maria Chimishkyan 6"/>
				</Col>
			</Row>
		</Container>
	
		<Container>
			<ProjectDescription projectModel={ProjectModel.Medium3}/>
	    </Container>
	</div>
	
	<ProjectIndex/>
</div>

  )
}

export default connect()(Medium);
