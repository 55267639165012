import React from 'react';
import ProjectIndexCard from './ProjectIndexCard';
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import '../App.css';

function ProjectIndex(props) {
  return (
  	
    <div className="project-index">
    <div className="mobile">
    <Container>
		<Row>
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.AdidasStateSneakers}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.AppleArtText}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.CashApp}/>
		</Col>
		
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.NYTimesRightToBeauty}/>
		</Col>
		</Row>

		<Row>
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.HighSnobiety}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Wired}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.LATimesClimateChange}/>
		</Col>
		
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.SpaceNinety8}/>
		</Col>
		</Row>

    	<Row>
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Apple}/>
		</Col>
		 
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Spotify}/>
		</Col> 

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Nike}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Atlantic}/>
		</Col>
		</Row>
		<Row>
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.YoungGuns}/>
		</Col>
		 
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.NYTimes}/>
		</Col> 

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.BulletinSwag}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.RBMA}/>
		</Col>
		</Row>

		<Row>
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.BandcampExperimental}/>
		</Col>
		
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.BulletinLWY}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.BloombergBusinessweekCover}/>
		</Col>
		
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.TheVerge}/>
		</Col>
		</Row>
		
		<Row>
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Bulletin}/>
		</Col>
		
		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Bushwig2018}/>
		 </Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.Bandcamp}/>
		</Col>

		<Col xs={6} sm={3}>
		<ProjectIndexCard projectModel={ProjectModel.BloombergGreen}/>
		</Col>
		</Row>	
		
		

	 </Container>
	 </div>
    </div>

  )
}

export default ProjectIndex;
