import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function Bushwig2019({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.Bushwig2019.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bushwig-2019/bushwig-2019-MariaChimishkyan-1.jpg" alt="Bushwig 2019 — Maria Chimishkyan 1"/>

	<div className="inner-wrapper">
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.Bushwig2019}/>
	    </Container>
	
		<div className="project-section">
		    <Container>
		    	<Row>
					<Col sm={6}>
					<img src= "img/project-page/bushwig-2019/bushwig-2019-MariaChimishkyan-2.jpg" alt="Bushwig 2019 — Maria Chimishkyan 2"/>
					<div className="project-caption">NYC Poster</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6}>
					<img src= "img/project-page/bushwig-2019/bushwig-2019-MariaChimishkyan-3.jpg" alt="Bushwig 2019 — Maria Chimishkyan 3"/>
					<div className="project-caption">Berlin Poster</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(Bushwig2019);
