import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function UsluAirlines({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);

  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.UsluAirlines.title}</title>
		</Helmet>
	
	    <Container>
	    	<Row>
				<Col sm={12}>
				<img src= "img/project-page/uslu-airlines/uslu-airlines-MariaChimishkyan-1.jpg" alt="Urban Outfitters - Uslu Airlines - Maria Chimishkyan 1"/>
				</Col>
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.UsluAirlines}/>
	
		</Container>
	
		<div className="project-section">
		    <Container>
		    	<Row className="top">
					<Col sm={6}>
					<img src= "img/project-page/uslu-airlines/uslu-airlines-MariaChimishkyan-2.jpg" alt="Urban Outfitters - Uslu Airlines - Maria Chimishkyan 2"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6}>
					<img src= "img/project-page/uslu-airlines/uslu-airlines-MariaChimishkyan-3.jpg" alt="Urban Outfitters - Uslu Airlines - Maria Chimishkyan 3"/>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
					<img src= "img/project-page/uslu-airlines/uslu-airlines-MariaChimishkyan-4.jpg" alt="Urban Outfitters - Uslu Airlines - Maria Chimishkyan 4"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6}>
					<img src= "img/project-page/uslu-airlines/uslu-airlines-MariaChimishkyan-5.jpg" alt="Urban Outfitters - Uslu Airlines - Maria Chimishkyan 5"/>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(UsluAirlines);
