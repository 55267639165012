import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BulletinRetailerNetwork({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.BulletinRetailerNetwork.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bulletin-retailer-network/bulletin-retailer-network_MariaChimishkyan-1.jpg" alt="Bulletin — Retailer Network — Maria Chimishkyan 1" />
	
	<div className="inner-wrapper">
		<Container>
			<ProjectDescription projectModel={ProjectModel.BulletinRetailerNetwork}/>
		</Container>
	
	
		<div className="project-section">
			<Container>
				<Row>
					<Col sm={6}>
						<img src= "img/project-page/bulletin-retailer-network/bulletin-retailer-network_MariaChimishkyan-3.jpg" alt="Bulletin — Retailer Network — Maria Chimishkyan 3"/>
						<div className="project-caption">FOLDOUT MAILER (FRONT)</div>
					</Col>
					<Col sm={6}>
						<img src= "img/project-page/bulletin-retailer-network/bulletin-retailer-network_MariaChimishkyan-2.jpg" alt="Bulletin — Retailer Network — Maria Chimishkyan 2"/>
						<div className="project-caption">FOLDOUT MAILER (Back)</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>
  )
}

export default connect()(BulletinRetailerNetwork);
