import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import './App.css';

function Footer(props) {
  return (

		<div className="footer">
			<div className="inner-wrapper">
				<Container>
					<div className="divider"></div>
					<Row>
						<Col sm={3}>
							<div className="footer-black">
							<a href="https://www.instagram.com/maria.chimi/">Instagram</a><br/>
							<a href="https://vimeo.com/mariachimi">Vimeo</a><br/>
							</div>
						</Col>

						<Col sm={3}>
							<div className="footer-black">
							<a href="https://www.are.na/maria-chimishkyan">Are.na</a><br/>
							<a href="https://twitter.com/mariachimi">Twitter</a>
							</div>
						</Col>
						
						<div className="spacer"></div>
							
						<Col sm={6}>
							<div className="subhead-black-right">
							hi@mariachimi.com
							</div>
						</Col>
					</Row>
				</Container>
			</div>
        </div>

  )
}

export default Footer;
