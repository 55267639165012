import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function TheVerge({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
<div className="wrapper">
	<div className="inner-wrapper">
		<Helmet>
		    <meta charSet="utf-8" />
		    <title>Maria Chimishkyan — {ProjectModel.TheVerge.title}</title>
		</Helmet>
			
		<Container>
	    	<Row>
	    		<Col sm={4}>
	    			<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/the-verge/the-verge_MariaChimishkyan_1.mp4"
					alt="The Verge — Maria Chimishkyan 1"/>
				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/the-verge/the-verge_MariaChimishkyan_2.mp4"
					alt="The Verge — Maria Chimishkyan 2"/>				</Col>
				
				<div className="spacer"></div>
				
				<Col sm={4}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/the-verge/the-verge_MariaChimishkyan_3.mp4"
					alt="The Verge — Maria Chimishkyan 3"/>
				</Col>
			</Row>
	
			<ProjectDescription projectModel={ProjectModel.TheVerge}/>
	
		</Container>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(TheVerge);
