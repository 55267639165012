import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BulletinLWY({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.BulletinLWY.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_1.jpg" alt="Bulletin — Lots Without You — Maria Chimishkyan 1" />
	
	<div className="inner-wrapper">
		<Container>
	    <ProjectDescription projectModel={ProjectModel.BulletinLWY}/>
	    </Container>
	
		<div className="project-section">
			<Container>
				<Row justify="center">
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-left">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_2.jpg" alt="Bulletin — Lots Without You — Maria Chimishkyan 2"/>
						</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-right">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_3.jpg" alt="Bulletin — Lots Without You — Maria Chimishkyan 3"/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg" style={{backgroundColor: "#fff"}}>
		<div className="inner-wrapper">
			<Container>
				<Row className="top">
					<Col sm={4}>
						<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_4.jpg" alt="Bulletin — Lots Without You — Maria Chimishkyan 4"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_5.jpg" alt="Bulletin — Lots Without You — Maria Chimishkyan 5"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_6.jpg" alt="Bulletin — Lots Without You — Maria Chimishkyan 6"/>
					</Col>
				</Row>
				<Row>
					<Col sm={4}>
						<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_7.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 7"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_8.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 8"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_9.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 9"/>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<div className="project-section-bg">
		<div className="inner-wrapper">
			<Container>
				<Row justify="center">
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-left">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_10.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 10"/>
						</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-right">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_11.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 11"/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg">
		<div className="inner-wrapper">
			<Container>
				<Row justify="center">
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-left">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_12.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 12"/>
						</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-right">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_13.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 13"/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg">
		<div className="inner-wrapper">
			<Container>
				<Row justify="center">
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-left">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_14.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 14"/>
						</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6} style={{padding: "0"}}>
						<div className="diptych-right">
							<img src= "img/project-page/bulletin-lwy/bulletin_lwy_MariaChimishkyan_15.jpg" alt="Bulletin Lots Without You — Maria Chimishkyan 15"/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>

	<ProjectIndex/>
</div>

  )
}

export default connect()(BulletinLWY);
