import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function BandcampExperimental({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);
  
  return (
<div className="wrapper">
	
	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.BandcampExperimental.title}</title>
	</Helmet>

	<img className="cover" src= "img/project-page/bandcamp-experimental/bandcamp-MariaChimishkyan-1.jpg" alt="Bushwig 2018 — Maria Chimishkyan 1"/>

	<div className="inner-wrapper">
		<Container>
	    	<ProjectDescription projectModel={ProjectModel.BandcampExperimental}/>
	    </Container>
		
		
		<div className="project-section">
		    <Container>
		    	<Row>
		    		<Col sm={3}>
					</Col>
					<Col sm={6}>
					<video className="reg" loop autoPlay style={{
					objectFit: 'contain',
					}}
					src="img/project-page/bandcamp-experimental/bandcamp-MariaChimishkyan-2.mp4"
					alt="Electric Objects - Maria Chimishkyan 2"/>
					</Col>
					<Col sm={3}>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	<ProjectIndex/>
</div>

  )
}

export default connect()(BandcampExperimental);
