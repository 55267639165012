import React, { useEffect } from "react";
import ProjectDescription from '../components/ProjectDescription';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import  ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function WSJLowBirths({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (
  <div className="wrapper">
 	 <div className="inner-wrapper">

		<Helmet>
			<meta charSet="utf-8" />
			<title>Maria Chimishkyan — {ProjectModel.WSJLowBirths.title}</title>
		</Helmet>
		
		<Container>
		    <Row>
				<Col sm={12}>
				<img src= "img/project-page/wsj-lowbirths/wsj-MariaChimishkyan-lowbirths.jpg" alt="The Wall Street Journal - Low Births - Maria Chimishkyan 1"/>
				</Col>
			</Row>
		
		<ProjectDescription projectModel={ProjectModel.WSJLowBirths}/>
		
		</Container>
	</div>
	<ProjectIndex/>	
</div>

  )
}

export default connect()(WSJLowBirths);
