import React, { useEffect } from "react";
import ProjectDescriptionWhite from '../components/ProjectDescriptionWhite';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-grid-system';
import ProjectModel from '../models/ProjectModel';
import ProjectIndex from '../components/ProjectIndex';
import { connect } from "react-redux";
import setCoverPresent from '../redux/actions';
import '../App.css';

function YoungGuns({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('yes'));
  }, []);

  return (
 <div className="page">

	<Helmet>
	    <meta charSet="utf-8" />
	    <title>Maria Chimishkyan — {ProjectModel.YoungGuns.title}</title>
	</Helmet>

	<video className="cover" loop autoPlay style={{
	objectFit: 'cover',
	}}
	src="img/project-page/young-guns/YoungGuns-MariaChimishkyan-1.mp4" 
	alt="Young Guns - Maria Chimishkyan 1"
	/>
	
	<div style={{backgroundColor: "#fff"}}>
		<div className="inner-wrapper">
			<Container>
				<ProjectDescriptionWhite projectModel={ProjectModel.YoungGuns} style={{color:'#000'}}/>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg" style={{backgroundColor: "#ffd0c2"}} >
		<div className="inner-wrapper">
			<Container>
				<Row>
					<Col sm={6}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-2.jpg" alt="Young Guns - Maria Chimishkyan 2"/>
						<div className="project-caption-black">Foldout Mailer (Front)</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-3.jpg" alt="Young Guns - Maria Chimishkyan 3"/>
						<div className="project-caption-black">Foldout Mailer (Back)</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	
	
	<div className="project-section-bg" style={{backgroundColor: "#ffffff"}}>
		<div className="inner-wrapper">
			<Container>
				<Row>
					<Col sm={12}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-10.jpg" alt="Young Guns - Maria Chimishkyan 10"/>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg" style={{backgroundColor: "#ffd0c2"}}>
		<div className="inner-wrapper">
			<Container>
				<Row>
					<Col sm={6}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-13.gif" alt="Young Guns - Maria Chimishkyan 13"/>
						<div className="project-caption-black">Social</div>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={6}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-14.gif" alt="Young Guns - Maria Chimishkyan 14"/>
						<div className="project-caption-black">Social</div>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg" style={{backgroundColor: "#fff"}}>
		<div className="inner-wrapper">
			<Container>
				<Row className="top">
					<Col sm={4}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-4.jpg" alt="Young Guns - Maria Chimishkyan 4"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-5.jpg" alt="Young Guns - Maria Chimishkyan 5"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-6.jpg" alt="Young Guns - Maria Chimishkyan 6"/>
					</Col>
				</Row>
				<Row>
					<Col sm={4}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-7.jpg" alt="Young Guns - Maria Chimishkyan 7"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-8.jpg" alt="Young Guns - Maria Chimishkyan 8"/>
					</Col>
					
					<div className="spacer"></div>
					
					<Col sm={4}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-9.jpg" alt="Young Guns - Maria Chimishkyan 9"/>
					</Col>
				</Row>
			</Container>
		</div>
	</div>
	
	<div className="project-section-bg" style={{backgroundColor: "#ffd0c2"}}>
		<div className="inner-wrapper">
			<Container>
				<Row>
					<Col sm={12}>
						<img src= "img/project-page/young-guns/YoungGuns-MariaChimishkyan-11.jpg" alt="Young Guns - Maria Chimishkyan 11"/>
						<div className="project-caption-black">Overview</div>
					</Col>					
				</Row>
			</Container>
		</div>
	</div>
	
	
	
	<ProjectIndex/>
	</div>

  )
}

export default connect()(YoungGuns);
