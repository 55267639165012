import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-grid-system';
import ProjectHomeCard from './components/ProjectHomeCard';
import  ProjectModel from './models/ProjectModel'
import { connect } from "react-redux";
import setCoverPresent from './redux/actions';
import './App.css';

function Homepage({dispatch}) {
  useEffect(() => {
    dispatch(setCoverPresent('no'));
  }, []);
  
  return (

<div className="wrapper">
	<div className="inner-wrapper">
	<Container>
				<Row>
					<Col sm={3}>
						<ProjectHomeCard projectModel={ProjectModel.NYTimesRightToBeauty}/>
						{/*<ProjectHomeCard projectModel={ProjectModel.CashAppCashTruths}/>*/}
						<ProjectHomeCard projectModel={ProjectModel.HighSnobiety}/>
						<ProjectHomeCard projectModel={ProjectModel.TheVergeOfflineActivities}/>
						<ProjectHomeCard projectModel={ProjectModel.BloombergBusinessweek7}/>
						<ProjectHomeCard projectModel={ProjectModel.BloombergBusinessweek5}/>
						<ProjectHomeCard projectModel={ProjectModel.Spotify}/>
						<ProjectHomeCard projectModel={ProjectModel.BloombergBusinessweekCover}/>
						<ProjectHomeCard projectModel={ProjectModel.BulletinSwag}/>
						<ProjectHomeCard projectModel={ProjectModel.BloombergGreen}/>
						{/*<ProjectHomeCard projectModel={ProjectModel.Elsewhere}/>*/}
					</Col>
					
					<Col sm={3}>
						<ProjectHomeCard projectModel={ProjectModel.NewYorker_BitterPill}/>
						<ProjectHomeCard projectModel={ProjectModel.LATimesClimateChange}/>
						<ProjectHomeCard projectModel={ProjectModel.AppleArtText}/>	
						<ProjectHomeCard projectModel={ProjectModel.Wired}/>
						<ProjectHomeCard projectModel={ProjectModel.YoungGuns}/>
						<ProjectHomeCard projectModel={ProjectModel.RBMA}/>
						<ProjectHomeCard projectModel={ProjectModel.LuxMagazine}/>		
						<ProjectHomeCard projectModel={ProjectModel.Bushwig2018}/>
						{/*<ProjectHomeCard projectModel={ProjectModel.BulletinRetailerNetwork}/>*/}
						<ProjectHomeCard projectModel={ProjectModel.Bandcamp}/>
					</Col>
					
					<Col sm={3}>
						<ProjectHomeCard projectModel={ProjectModel.AdidasStateSneakers}/>
						<ProjectHomeCard projectModel={ProjectModel.NewYorker_MDMA}/>
						<ProjectHomeCard projectModel={ProjectModel.NewYorker}/>
						<ProjectHomeCard projectModel={ProjectModel.Apple}/>
						{/*<ProjectHomeCard projectModel={ProjectModel.Microsoft}/>*/}
						<ProjectHomeCard projectModel={ProjectModel.BloombergBusinessweek4}/>
						<ProjectHomeCard projectModel={ProjectModel.Nike}/>
						<ProjectHomeCard projectModel={ProjectModel.BandcampExperimental}/>
						<ProjectHomeCard projectModel={ProjectModel.SpaceNinety8}/>						
					</Col>
					
					<Col sm={3}>
						<ProjectHomeCard projectModel={ProjectModel.CashApp}/>
						<ProjectHomeCard projectModel={ProjectModel.TheVerge}/>
						<ProjectHomeCard projectModel={ProjectModel.NYTimes}/>
						<ProjectHomeCard projectModel={ProjectModel.Atlantic}/>
						<ProjectHomeCard projectModel={ProjectModel.WSJ}/>
						<ProjectHomeCard projectModel={ProjectModel.TheBaffler}/>
						<ProjectHomeCard projectModel={ProjectModel.BulletinLWY}/>
						<ProjectHomeCard projectModel={ProjectModel.ElectricObjects}/>						
					</Col>
					
				</Row>
				
				
	</Container>
	</div>
</div>


  )
}

export default connect()(Homepage);

